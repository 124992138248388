@import url(https://fonts.googleapis.com/css?family=Jost:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFEAEA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

html,
body {
  scroll-behavior: smooth;
}

.textShadow:hover, .textShadow:active {
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 6px rgb(255, 242, 242), 0 0 10px rgb(255, 204, 204)
}

.titleColor {
  color: #929292 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;